<template>
  <span>
    Показано {{ firstItemNumber }} - {{ lastItemNumber }} из
    <span class="text-primary fw-bold">{{ count }}</span>
  </span>
</template>

<script setup lang="ts">
const props = defineProps({
  count: { type: Number, default: 0 },
  page: { type: Number, default: 0 },
  perPage: { type: Number, default: 10 },
});

//-----COMPUTED-----\\
const firstItemNumber = computed<number>(() => props.count === 0 ? 0 : ((props.page - 1) * props.perPage + 1));
const lastItemNumber = computed<number>(() => {
  const tmp = props.page * props.perPage - props.count;

  if (tmp < 0) {
    return props.page * props.perPage;
  }
  return props.page * props.perPage - tmp;
});

</script>

<style scoped lang="scss">

</style>